.backContent {
  background: var(--foreground-rgb);
  overflow: hidden;

  .topContainer {
    background-color: #e7f2e9;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .content {
    background-color: #2b5442;
    padding: 50px 120px;
    text-align: center;

    > .topInfoContainer {
      color: var(--other-text-color);
      line-height: 1.5;
      text-align: left;

      > p {
        &:nth-child(1) {
          font-weight: 400;
          font-size: 12px;
        }
        &:nth-child(2) {
          font-weight: 500;
          font-size: 24px;
        }
        &:nth-child(3) {
          font-weight: 500;
          font-size: 16px;
        }
      }
    }

    > .midInfoContainer {
      background-color: var(--foreground-rgb);
      margin-top: 50px;
      border-radius: 10px;
      padding: 50px 32px;
      // background-image: url('/images/planbowl/home-meet-bg.webp');
      background-image: url('https://res-front.pumpsoul.com/pb-prod/image/home-meet-bg.webp');
      background-position: left bottom;
      background-size: cover;
      background-repeat: no-repeat;

      display: flex;

      > .left {
        width: 100%;
        margin-right: 20px;

        > img {
          width: 100%;
          object-fit: contain;
        }

        > .leftInfo {
          margin-top: 20px;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          text-align: left;

          > div {
            margin-right: 10px;

            > p {
              &:first-child {
                font-size: 18px;
                color: var(--app-primary-color);
                margin-bottom: 10px;
              }

              &:last-child {
                font-size: 16px;
                color: var(--app-text-60-color);
              }
            }
          }
        }
      }

      > .right {
        width: 300px;
        background: #eff7f4;
        color: var(--app-text-color);
        border-radius: 10px;
        text-align: left;
        padding: 32px;

        > p {
          &:nth-child(1) {
            color: var(--app-primary-color);
            margin-bottom: 20px;
          }
        }
      }
    }

    > .bottomInfoContainer {
      margin-top: 50px;
      color: var(--other-text-color);
      display: flex;
      align-items: center;
      justify-content: center;

      > span {
        font-size: 24px;

        &:nth-child(2) {
          background-color: var(--foreground-rgb);
          color: var(--app-primary-color);
          font-size: 16px;
          border-radius: 10px;
          margin-left: 50px;
          padding: 16px;
          min-width: 150px;
          cursor: pointer;
        }
      }
    }
  }
}

@media (min-width: 786px) and (max-width: 900px) {
  .backContent {
    .content {
      padding: 30px 50px;

      > .midInfoContainer {
        margin-top: 30px;
        padding: 30px 20px;
        > .left {
          > .leftInfo {
            > div {
              > p {
                &:first-child {
                  font-size: 16px;
                }

                &:last-child {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }

      > .bottomInfoContainer {
        margin-top: 30px;
      }
    }
  }
}

@media screen and (max-width: 786px) {
  .backContent {
    .content {
      padding: 24px;

      > .midInfoContainer {
        margin-top: 15px;
        padding: 15px 10px;

        display: block;

        > .left {
          width: 100%;
          margin-right: 0;

          > .leftInfo {
            > div {
              > p {
                &:first-child {
                  font-size: 14px;
                }

                &:last-child {
                  font-size: 12px;
                }
              }
            }
          }
        }

        > .right {
          margin-top: 30px;
          width: 100%;
        }
      }

      > .bottomInfoContainer {
        margin-top: 15px;
      }
    }
  }
}

